import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/404.scss"
import img from "../images/andre-ojos.jpg"

var mensajes = [
  "Error en la matrix",
  "Demasiado Curioso quizás?",
  "que hago aquí?, a donde me dirijo?",
  "definitivamente estas perdido ",
]
var emoticons = ["🤹", "🤸", "🎪", "😲"]

var randomPhrase = mensajes[Math.floor(Math.random() * mensajes.length)]
var randomEmoticon = emoticons[Math.floor(Math.random() * emoticons.length)]

const NotFoundPage = () => (
  <Layout className="not-found">
    <Seo title={`404: La página que buscas no existe`} />
    <div className="info-container">
      <h1 className="phrase">
        404 La página que buscas no existe {randomEmoticon}
      </h1>
      <div className="juggler-emoticon"></div>
      <p className="random-message">{randomPhrase}</p>
    </div>
    <div className="image-container">
      <img alt="not found" src={img} />
    </div>
  </Layout>
)

export default NotFoundPage
